<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :searchControl="searchControl"
      :apiName="apiName"
      :editTitle="'巡检位置'"
      :btnFlag="btnFlag"
    >
      <div slot="toolSub" selecTionArr="">
        <el-button
          type="success"
          @click="operationVisible = true"
          icon="el-icon-plus"
          >新增</el-button
        >
      </div>
    </app-list>

    <el-dialog
      :title="'新增-巡检位置'"
      :visible.sync="operationVisible"
      center
      width="800px"
      :close-on-click-modal="false"
    >
      <el-form label-width="80px" :inline="true" class="inlineForm">
        <el-form-item label="巡检地图">
          <el-select
            @change="creatCanvas"
            v-model="form.meritPatrolMapId"
            :style="{ width: '270px' }"
          >
            <el-option
              v-for="(item, index) in mapList"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="巡检位置">
          <el-input
            v-model="form.locationName"
            :style="{ width: '270px' }"
          ></el-input>
        </el-form-item>
        <el-form-item label="X轴坐标">
          <el-input
            v-model="form.abscissa"
            disabled
            :style="{ width: '270px' }"
          ></el-input>
        </el-form-item>
        <el-form-item label="Y轴坐标">
          <el-input
            v-model="form.ordinate"
            disabled
            :style="{ width: '270px' }"
          ></el-input>
        </el-form-item>
        <el-form-item :style="{ width: '750px' }">
          <div class="mapCon">
            <img :src="form.url" @click="getCoord" ref="mapPic" />
            <i
              v-show="form.abscissa !== 0"
              :style="{ left: form.abscissa + 'px', top: form.ordinate + 'px' }"
            ></i>
            <!-- <canvas id="mapCon"></canvas> -->
          </div>
        </el-form-item>
        <div class="flex justify-center align-center">
          <el-button @click="operationVisible = false">取消</el-button>
          <el-button type="primary" @click="handelAdd()">提交</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/meritLocationManage",
      searchVal: "",
      form: {
        meritPatrolMapId: 0,
        locationName: "",
        abscissa: 0,
        ordinate: 0,
        url: "",
      },
      mapList: [],
      searchControl: {
        firstSoltSpan: [2, 2, 2, 2],
        secondSoltSpan: [4, 4, 4, 4],
        thirdSoltSpan: [18, 18, 18, 18],
      },
      operationVisible: false,
      btnFlag: { addShow: false, editShow: false, deleteShow: true },
      searchQuery: { num: 0 },
      props: [
        {
          label: "id",
          prop: "id",
          align: "center",
        },

        {
          label: "位置",
          prop: "locationName",
          align: "center",
          width: "200px",
        },
        {
          label: "二维码",
          prop: "qrCode",
          align: "center",
          type: "image",
        },

        {
          label: "创建时间",
          prop: "createTime",
          align: "center",
        },
      ],

      formProps: [
        {
          label: "设备名称",
          prop: "deviceName",
          type: "input",
        },

        {
          label: "设备编号",
          prop: "deviceNum",
          type: "input",
        },
        {
          label: "位置",
          prop: "locationName",
          type: "input",
        },
        {
          label: "设备状态",
          prop: "deviceStatus",
          type: "select",
          selectData: [
            { label: "正常", value: 0 },
            { label: "异常", value: 1 },
          ],
        },
        {
          label: "备注",
          prop: "issue",
          type: "input",
        },
      ],
    };
  },
  created() {
    this.getMapList();
  },
  methods: {
    getCoord(e) {
      let top = this.$refs.mapPic.getBoundingClientRect().top;
      let left = this.$refs.mapPic.getBoundingClientRect().left;
      this.form.abscissa = e.clientX - left;
      this.form.ordinate = e.clientY - top;
    },
    async getMapList() {
      let _this = this;
      await api.common.list({ apiName: "/meritPatrolMap" }).then((res) => {
        res.data.data.forEach((element) => {
          _this.mapList.push({
            label: element.detailLocation,
            value: element.id,
            url: element.mapImg,
          });
        });
      });
    },

    async handelAdd() {
      let res = await api.common.create({
        apiName: "/meritLocationManage",
        meritPatrolMapId: this.form.meritPatrolMapId,
        locationName: this.form.locationName,
        abscissa: this.form.abscissa,
        ordinate: this.form.ordinate,
      });
      if (res.code == 200) {
        if (res.code == 200) {
          this.$message({ message: res.message, type: "success" });
          this.operationVisible = false;
          this.searchQuery.num++;
        } else {
          this.$message(res.message);
        }
      }
    },
    creatCanvas() {
      let obj = this.mapList.find(
        (item) => item.value == this.form.meritPatrolMapId
      );
      console.log(this.form.meritPatrolMapId, obj);

      this.form.url = "api/" + obj.url;
      console.log("url", obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.mapCon {
  position: relative;
}
.mapCon i {
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  background: url("~@/assets/images/Location.png") no-repeat;
  background-size: 100% auto;
}
.mapCon img {
  width: 750px;
}
</style>
